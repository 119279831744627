import colors from "@styles/colors"

const styles = {
    mark: {
        position: 'relative'
    },
    markIcon: {
        position: 'absolute',
        top: -20,
        left: -10 
    },
    markTexts: {
        position: 'absolute',
        top: -22,
        left: 18,
        width: 250
    },
    markLabel: {
        mb: -.6,
        fontWeight: 'bold'
    },
    markLink: {
        textDecoration: 'none',
        color: colors.primary
    }
}

export default styles