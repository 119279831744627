const AGENCIES = {
    pa00: {
        name: 'PA 0 - Turvo (SC)',
        id: 'pa00',
        coords: { lat: -28.928635, lng: -49.676051 }
    },
    pa01: {
        name: 'PA 1 - Ermo (SC)',
        id: 'pa01',
        coords: { lat: -28.984240, lng: -49.642510 }
    },
    pa02: {
        name: 'PA 2 - São João do Sul (SC)',
        id: 'pa02',
        coords: { lat: -29.223024, lng: -49.807565 }
    },
    pa03: {
        name: 'PA 3 - Timbé do Sul (SC)',
        id: 'pa03',
        coords: { lat: -28.832402, lng: -49.845543 }
    },
    pa04: {
        name: 'PA 4 - Meleiro (SC)',
        id: 'pa04',
        coords: { lat: -28.8339814, lng: -49.63525 }
    },
    pa05: {
        name: 'PA 5 - Nova Veneza (SC)',
        id: 'pa05',
        coords: { lat: -28.6372074, lng: -49.5020832 }
    },
    pa06: {
        name: 'PA 6 - Araranguá C.A. (SC)',
        id: 'pa06',
        coords: { lat: -28.9424724, lng: -49.492408 }
    },
    pa061: {
        name: 'PA 6 - Araranguá C. (SC)',
        id: 'pa061',
        coords: { lat: -28.9362129, lng: -49.4868028 }
    },
    pa07: {
        name: 'PA 7 - Passo de Torres (SC)',
        id: 'pa07',
        coords: { lat: -29.324646, lng: -49.720700 }
    },
    pa08: {
        name: 'PA 8 - Forquilhinha (SC)',
        id: 'pa08',
        coords: { lat: -28.751820, lng: -49.472984 }
    },
    pa09: {
        name: 'PA 9 - Morro Grande (SC)',
        id: 'pa09',
        coords: { lat: -28.802464, lng: -49.715711 }
    },
    pa10: {
        name: 'PA 10 - Arroio do Silva (SC)',
        id: 'pa10',
        coords: { lat: -28.9858777, lng: -49.4169055 }
    },
    pa11: {
        name: 'PA 11 - Urussanga (SC)',
        id: 'pa11',
        coords: { lat: -28.519495, lng: -49.32283 }
    },
    pa12: {
        name: 'PA 12 - Santa Luzia (SC)',
        id: 'pa12',
        coords: { lat: -28.702309, lng: -49.424421 }
    },
    pa13: {
        name: 'PA 13 - Criciúma (SC)',
        id: 'pa13',
        coords: { lat: -28.6798051, lng: -49.3669038 }
    },
    pa14: {
        name: 'PA 14 - Siderópolis (SC)',
        id: 'pa14',
        coords: { lat: -28.5988096, lng: -49.4272657 }
    },
    pa15: {
        name: 'PA 15 - Cocal do Sul (SC)',
        id: 'pa15',
        coords: { lat: -28.6027721, lng: -49.3278714 }
    },
    pa16: {
        name: 'PA 16 - Capivari de Baixo (SC)',
        id: 'pa16',
        coords: { lat: -28.4435868, lng: -48.9561429 }
    },
    pa17: {
        name: 'PA 17 - Rio Maina (SC)',
        id: 'pa17',
        coords: { lat: -28.6781631, lng: -49.4181195 }
    },
    pa18: {
        name: 'PA 18 - São Luiz (SC)',
        id: 'pa18',
        coords: { lat: -28.6917998, lng: -49.3772728 }
    },
    pa19: {
        name: 'PA 19 - Osório (RS)',
        id: 'pa19',
        coords: { lat: -29.8900654, lng: -50.2727784 }
    },
    pa20: {
        name: 'PA 20 - Torres (RS)',
        id: 'pa20',
        coords: { lat: -29.3344812, lng: -49.7347389 }
    },
    pa21: {
        name: 'PA 21 - Lauro Muller (SC)',
        id: 'pa21',
        coords: { lat: -28.3929936, lng: -49.4004706 }
    },
    pa22: {
        name: 'PA 22 - Sto. Antônio da Patrulha (RS)',
        id: 'pa22',
        coords: { lat: -29.834573, lng: -50.519339 }
    },
    pa23: {
        name: 'PA 23 - Tramandaí (RS)',
        id: 'pa23',
        coords: { lat: -29.9849205, lng: -50.136438 }
    },
    pa24: {
        name: 'PA 24 - Garopaba (SC)',
        id: 'pa24',
        coords: { lat: -28.0288362, lng: -48.619513 }
    },
    pa25: {
        name: 'PA 25 - Capão da Canoa (RS)',
        id: 'pa25',
        coords: { lat: -29.7524622, lng: -50.0147546 }
    },
    pa26: {
        name: 'PA 26 - Imbituba (SC)',
        id: 'pa26',
        coords: { lat: -28.2414177, lng: -48.6713172 }
    }
}


export default AGENCIES