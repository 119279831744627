import React, { useRef, useState, useEffect } from 'react'

import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded'
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded'
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'
import dayjs from 'dayjs'
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"

import Item from './Item'
import { TIMELINE, ACTIVE } from '@constants/exhibition'
import useFolderImages from '@hooks/useFolderImages'
import { Feedback } from '@components/molecules'
import styles from './styles'

const Timeline = props => {
    const { onShowInMap } = props
    const sliderRef = useRef(null)
    const [position, setPosition] = useState(0)
    const agenciesImgs = useFolderImages('agencies')

    useEffect(() => {
        const index = TIMELINE.findIndex(el => dayjs().isAfter(dayjs(el.startDate)) && dayjs().isBefore(dayjs(el.endDate)))
        if (index > 0 && sliderRef.current) {
            sliderRef.current.slickGoTo(index)
        }
    }, [])

    if (!ACTIVE) return (
        <Feedback title='A exposição das obras vencedoras da 4ª Edição encerraram' image='ill_done_checking'
            description='Mas fique ligado, já estamos nos preparando para a 5ª Edição!' sx={{ mt: 0 }}
        />
    )
    return (
        <Box sx={ styles.background }>
            <Slider beforeChange={ (_, after) => setPosition(after) } swipe={ false } ref={ sliderRef } centerMode dots={ false } accessibility slidesToShow={ 3 }
                slidesToScroll={ 1 } arrows={ false } responsive={[ { breakpoint: 800, settings: { slidesToShow: 1 }} ]}
            >
                { TIMELINE.map((item, index) => (
                    <Item key={ index } { ...item } image={ agenciesImgs.find(({ name }) => name === item.agency) } focused={ index === position }
                        onShowInMap={ () => onShowInMap(item.agency) }
                    />
                ))}
            </Slider>
            <div style={ styles.arrowsArea }>
                <IconButton sx={{ mr: 1 }} onClick={ () => sliderRef.current.slickPrev() } aria-label='Mostrar etapa anterior'>
                    <ChevronLeftRoundedIcon fontSize='large' sx={ styles.icon }/>
                </IconButton>
                <IconButton sx={{ ml: 1 }} onClick={ () => sliderRef.current.slickNext() } aria-label='Mostrar próxima etapa'>
                    <ChevronRightRoundedIcon fontSize='large' sx={ styles.icon }/>
                </IconButton>
            </div>
        </Box>
    )
}

export default Timeline
