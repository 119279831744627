import AGENCIES from "./agencies"

/**If true, the carousel with the timeline will be showed */
export const ACTIVE = false

export const TIMELINE = [
    {
        startDate:'2021-10-03',
        endDate: '2021-10-09',
        day: '04 à 08',
        month: 'Outubro - 2021',
        agency: AGENCIES.pa00.id
    },
    {
        day: '11 à 15',
        startDate:'2021-10-10',
        endDate: '2021-10-16',
        month: 'Outubro - 2021',
        agency: AGENCIES.pa01.id
    },
    {
        day: '18 à 22',
        startDate:'2021-10-17',
        endDate: '2021-10-23',
        month: 'Outubro - 2021',
        agency: AGENCIES.pa03.id
    },
    {
        day: '25 à 29',
        startDate:'2021-10-24',
        endDate: '2021-10-30',
        month: 'Outubro - 2021',
        agency: AGENCIES.pa09.id
    },
    {
        day: '01 à 05',
        startDate:'2021-10-30',
        endDate: '2021-11-06',
        month: 'Novembro - 2021',
        agency: AGENCIES.pa04.id
    },
    {
        day: '08 à 12',
        startDate:'2021-11-07',
        endDate: '2021-11-13',
        month: 'Novembro - 2021',
        agency: AGENCIES.pa02.id
    },
    {
        day: '15 à 19',
        startDate:'2021-11-14',
        endDate: '2021-11-20',
        month: 'Novembro - 2021',
        agency: AGENCIES.pa07.id
    },
    {
        day: '22 à 26',
        startDate:'2021-11-21',
        endDate: '2021-11-27',
        month: 'Novembro - 2021',
        agency: AGENCIES.pa20.id
    },
    {
        day: '29 à 03',
        startDate:'2021-11-28',
        endDate: '2021-12-04',
        month: 'Novembro e Dezembro - 2021',
        agency: AGENCIES.pa25.id
    },
    {
        day: '06 à 10',
        startDate:'2021-12-05',
        endDate: '2021-12-11',
        month: 'Dezembro - 2021',
        agency: AGENCIES.pa23.id
    },
    {
        day: '13 à 17',
        startDate:'2021-12-12',
        endDate: '2021-12-18',
        month: 'Dezembro - 2021',
        agency: AGENCIES.pa19.id
    },
    {
        day: '20 à 30',
        startDate:'2021-12-19',
        endDate: '2021-12-30',
        month: 'Dezembro - 2021',
        agency: AGENCIES.pa22.id
    },
    {
        day: '03 à 07',
        startDate:'2021-12-31',
        endDate: '2022-01-08',
        month: 'Janeiro - 2022',
        agency: AGENCIES.pa06.id
    },
    {
        day: '10 à 14',
        startDate:'2022-01-09',
        endDate: '2022-01-15',
        month: 'Janeiro - 2022',
        agency: AGENCIES.pa061.id
    },
    {
        day: '17 à 21',
        startDate:'2022-01-16',
        endDate: '2022-01-22',
        month: 'Janeiro - 2022',
        agency: AGENCIES.pa10.id
    },
    {
        day: '24 à 28',
        startDate:'2022-01-23',
        endDate: '2022-01-29',
        month: 'Janeiro - 2022',
        agency: AGENCIES.pa08.id
    },
    {
        day: '31 à 04',
        startDate:'2022-01-30',
        endDate: '2022-02-05',
        month: 'Janeiro e Fevereiro - 2022',
        agency: AGENCIES.pa12.id
    },
    {
        day: '07 à 11',
        startDate:'2022-02-06',
        endDate: '2022-02-12',
        month: 'Fevereiro - 2022',
        agency: AGENCIES.pa13.id
    },
    {
        day: '14 à 18',
        startDate:'2022-02-13',
        endDate: '2022-02-19',
        month: 'Fevereiro - 2022',
        agency: AGENCIES.pa17.id
    },
    {
        day: '21 à 04',
        startDate:'2022-02-20',
        endDate: '2022-03-05',
        month: 'Fevereiro e Março - 2022',
        agency: AGENCIES.pa18.id
    },
    {
        day: '07 à 11',
        startDate:'2022-03-06',
        endDate: '2022-03-12',
        month: 'Março - 2022',
        agency: AGENCIES.pa15.id
    },
    {
        day: '14 à 18',
        startDate:'2022-03-13',
        endDate: '2022-03-19',
        month: 'Março - 2022',
        agency: AGENCIES.pa11.id
    },
    {
        day: '21 à 25',
        startDate:'2022-03-20',
        endDate: '2022-03-26',
        month: 'Março - 2022',
        agency: AGENCIES.pa21.id
    },
    {
        day: '28 à 01',
        startDate:'2022-03-27',
        endDate: '2022-04-02',
        month: 'Março e Abril - 2022',
        agency: AGENCIES.pa14.id
    },
    {
        day: '04 à 08',
        startDate:'2022-04-03',
        endDate: '2022-04-09',
        month: 'Abril - 2022',
        agency: AGENCIES.pa05.id
    },
    {
        day: '11 à 22',
        startDate:'2022-04-10',
        endDate: '2022-04-23',
        month: 'Abril - 2022',
        agency: AGENCIES.pa16.id
    },
    {
        day: '25 à 29',
        startDate:'2022-04-24',
        endDate: '2022-04-30',
        month: 'Abril - 2022',
        agency: AGENCIES.pa26.id
    },
    {
        day: '02 à 06',
        startDate:'2022-05-01',
        endDate: '2022-05-07',
        month: 'Maio - 2022',
        agency: AGENCIES.pa24.id
    },
]