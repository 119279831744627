import React, { useState, useEffect } from 'react'
import GoogleMapReact from 'google-map-react'

import RoomIcon from '@mui/icons-material/RoomRounded'
import Typography from '@mui/material/Typography'

import { MAP_API_KEY } from '@constants/general'
import AGENCIES from '@constants/agencies'
import { ACTIVE } from '@constants/exhibition'
import styles from './styles'

const DEF_CENTER = { lat: -28.9204522, lng: -49.6920628 }

const Mark = ({ label, lat, lng }) => (
    <div style={ styles.mark }>
        <RoomIcon sx={ styles.markIcon }/>
        { label &&
            <div style={ styles.markTexts }>
                <Typography variant='body2' sx={ styles.markLabel }>{ label }</Typography>
                <a target="_blank" rel="noreferrer" style={ styles.markLink } href={ `https://www.google.com.br/maps/?q=${ lat },${ lng }` }>Abrir no Maps</a>
            </div>
        }
    </div>
)

const Map = props => {
    const { currentPosition } = props
    const [center, setCenter] = useState(DEF_CENTER)
    const [zoom, setZoom] = useState(8)
    const [showLabel, setShowLabel] = useState(false)

    useEffect(() => {
        if (currentPosition) {
            setCenter(currentPosition)
            setZoom(15)
            setShowLabel(true)
        }
    }, [currentPosition])

    return ACTIVE ? (
        <div style={{ height: '80vh', width: '100%' }}>
            <GoogleMapReact bootstrapURLKeys={{ key: MAP_API_KEY, language: 'pt-br' }} defaultCenter={ DEF_CENTER }
                defaultZoom={ 8 } center={ center } zoom={ zoom } onZoomAnimationEnd={ val => setShowLabel(val > 12) }
            >
                { Object.values(AGENCIES).map(agency => (
                    <Mark { ...agency.coords } key={ agency.id } label={ showLabel ? agency.name : null }/>
                ))}
            </GoogleMapReact>
        </div>
    ) : null
}

export default Map