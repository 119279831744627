import colors from "@styles/colors"

const styles = {
    background: {
        mb: 4
    },
    arrowsArea: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 20,
    },
    icon: {
        color: 'primary.main',
        border: '1px solid #c1c1c1',
        borderRadius: '50%'
    },
    itemBackground: focused => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        opacity: focused ? 1 : .5,
        transform: `scale(${ focused ? 1.1 : 1 }, ${ focused ? 1.1 : 1 })`,
        transformOrigin: '50% 100%',
        transition: 'transform 1s, opacity 1s, transformOrigin 1s',
    }),
    itemImg: {
        overflow: 'hidden',
        width: 150,
        height: 150,
        marginTop: 45,
        marginBottom: 10,
        borderRadius: '50%'
    },
    itemLineArea: {
        marginTop: 5,
        marginBottom: 20,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    itemCircle: {
        backgroundColor: colors.primary,
        width: 15,
        height: 15,
        borderRadius: '50%'
    },
    itemLine: {
        height: 2,
        width: '50%',
        opacity: .4,
        backgroundColor: colors.primary
    }
}

export default styles