import React, { useState } from "react"

import { Layout } from "@components/organisms"
import { PageHeader } from "@components/molecules"
import { Timeline, Map } from "@pagesComponents/exhibition"
import AGENCIES from "@constants/agencies"

const Exhibition = () => {
    const [showInMap, setShowInMap] = useState(null)

    const handleShowInMap = agency => {
        setShowInMap(AGENCIES[agency].coords)
        window.scrollTo(0, 950)
    }

    return (
        <Layout seo={{ title: 'Exibição', description: 'Acompanhe a exposição das obras vencedoras do Concurso de fotografia - Foco no Cooperativismo' }}> 
            <PageHeader icon={{ name: 'collaboration', alt: 'Icone Colaboração' }} description='Após a premiação, as fotos vencedoras são expostas em todas as agências do
                Sicoob Credisulca, seguindo o cronograma abaixo (Sujeito a alterações)'
            >
                Exposição das obras vencedoras
            </PageHeader>
            <Timeline onShowInMap={ handleShowInMap }/>
            <Map currentPosition={ showInMap }/>
        </Layout>
    )
}

export default Exhibition
